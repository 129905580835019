body {
    background-color: black;
    color: white;
    text-transform: uppercase;
}

.playerByTag {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.playerByTag-first {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.playerByTag-clan {
    display: flex;
    flex-direction: column;
    background-color: gray;
    border-radius: 7px;
    padding: 3px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.rotate_left,
.rotate_right {
    padding: 5px;
}

a {
    background-color: initial;
    background-image: linear-gradient(-180deg, white, white);
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: black;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    min-height: 44px;
    line-height: 44px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    text-decoration: none;
}

a:hover {
    background: #00bd68;
}

.war-class {
    color: white;
    background-color: gray;
    box-shadow: none;
    background-image: linear-gradient(rgb(102, 102, 102), rgb(124, 124, 124));
    border-radius: 0px;
    cursor: default;
}

.war-class:hover {
    background: gray;
    background-image: linear-gradient(rgb(102, 102, 102), rgb(124, 124, 124));
    cursor: default;
}

b,
em {
    color: rgb(187, 177, 177);
}

b {
    font-size: 1.5rem;
}

.loading-img {
    background-color: gray;
    width: 100vw;
    height: 100vh;
    background-image: url("./components/static/loading.png");
    background-size: 175px;
}